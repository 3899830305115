<template>
  <div class="FinanceZ-page">
    <!-- 相关文件 -->
    <div class="content_left">
      <treedata @projectId-child="projectIdClick"></treedata>
    </div>
    <div class="FinanceZ-content">
      <div class="FinanceZ-header-upload">
        <span>说明:</span>
        <el-input placeholder="请输入说明" v-model="Key"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
        <el-button type="primary" icon="el-icon-bottom" @click="dowFiles">打包下载</el-button>
        <el-button type="primary" icon="el-icon-bottom" @click="exportFiles">导出表格</el-button>
        <el-button type="primary" icon="el-icon-top" @click="add">上传文件</el-button>
      </div>
      <div class="content" style="height: 660px">
        <el-table
            :data="tableData"
            style="width: 100%"
            max-height="660"
            :header-cell-style="{
              color: '#333333',
              backgroundColor: '#FBFBFD',
            }"
            :row-key="getRowKey"
            border
            ref="multipleTable"
            @selection-change="handleSelectionChange"
            >
          <el-table-column
          :reserve-selection="true" 
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column
              label="序号"
              type="index"
              align="center"
              width="62">
          </el-table-column>
          <el-table-column
            prop="FileName"
            label="文件名称"
            align="center"
            min-width="200">
          </el-table-column>
          <el-table-column
              prop="Remarks"
              label="说明"
              align="center"
              min-width="200">
          </el-table-column>
<!--          <el-table-column-->
<!--              prop="SerialNumber"-->
<!--              label="所属项目/课题/子课题"-->
<!--              align="center"-->
<!--              min-width="200">-->
<!--          </el-table-column>-->
          <el-table-column
            prop="HospitalName"
            label="所属单位"
            align="center"
            min-width="200"
          >
          </el-table-column>
          <el-table-column
            prop="TopicName"
            label="所属项目/课题/子课题"
            align="center"
            min-width="200">
          </el-table-column>
          <el-table-column
              prop="UserName"
              label="上传人"
              align="center"
              width="150">
          </el-table-column>
          <el-table-column
              prop="CreateDate"
              align="center"
              label="上传时间"
              width="150"
          >
            <template slot-scope="scope">
              <span>{{scope.row.CreateDate.substring(0,10)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="160">
            <template slot-scope="scope">
<!--              <el-link-->
<!--                  style="font-size:16px;color: #3978E7;margin-right: 11px"-->
<!--                  type="text"-->
<!--                  :underline="false"-->
<!--                  @click="edit(scope.row)"-->
<!--              ><i class="el-icon-edit-outline"></i>编辑</el-link>-->
              <el-link
                  style="font-size:16px;color: #3978E7;margin-right: 11px"
                  type="text"
                  :underline="false"
                  @click="delFinanceZ(scope.row)"
              ><i class="el-icon-delete"></i>删除</el-link>
              <el-link
                  style="font-size:16px;color: #3978E7"
                  type="text"
                  :underline="false"
                  @click="pre(scope.row.Id)"
              ><img src="../../../assets/image/notice/query.png" alt="" style="width: 16px;height: 16px;vertical-align: -3px">查看</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
            background
            @size-change="handleSizeChange"
            :page-sizes="[10, 20, 30, 40,50,60,70,80,90,100]"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="eachpage"
            layout="total,sizes,prev, pager, next, jumper"
            :total="PageCount">
        </el-pagination>
      </div>
    </div>
    <div class="FinanceZ-dialog">
      <el-dialog
          :visible.sync="dialogVisible"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">上传文件</span>
        </div>
        <el-form ref="form" label-width="100px">
<!--          <el-form-item label="文件名称:">-->
<!--            <el-input  v-model="fileName"></el-input>-->
<!--          </el-form-item>-->
          <el-form-item label="说明:" required>
            <el-input type="textarea" v-model="Remarks"></el-input>
          </el-form-item>
          <el-form-item label="文件上传:" required>
            <el-upload
                class="upload-demo"
                ref="newupload"
                accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.zip"
                multiple
                action="#"
                :file-list="fileLists"
                :auto-upload="false"
                :on-change="handleFileChange"
                :before-remove="handleRemove"
                :before-upload="beforeUpload"   
                :on-progress="fileUploading"
            >
              <el-button size="medium" plain icon="el-icon-top" style="color: #4d90f1;border: 1px solid #4d90f1">上传文件</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="btn">
          <button  @click="dialogVisible = false">取消</button>
          <el-button  @click="determine" :loading="disabled" >确定</el-button>
        </div>
      </el-dialog>
    </div>
<!--    <div class="FinanceZ-dialog">-->
<!--      <el-dialog-->
<!--        :visible.sync="dialogEdit"-->
<!--      >-->
<!--        <div slot="title" class="header-title">-->
<!--          <span class="title-name"></span>-->
<!--          <span class="title-age">编辑</span>-->
<!--        </div>-->
<!--        <el-form ref="form" label-width="100px">-->
<!--          <el-form-item label="文件名称:">-->
<!--            <el-input  v-model="editForm.FileName"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="课题序号:">-->
<!--            <el-input v-model="editForm.SerialNumber" disabled>-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="上传人:">-->
<!--            <el-input v-model="editForm.UserName" readonly></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="上传日期:">-->
<!--            <el-input v-model="editForm.CreateDate" readonly></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="备注说明:">-->
<!--            <el-input v-model="editRemarks" type="textarea"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="文件:">-->
<!--            <el-upload-->
<!--              class="upload-demo"-->
<!--              accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc"-->
<!--              multiple-->
<!--              action="#"-->
<!--              :file-list="fileEditList"-->
<!--              :auto-upload="false"-->
<!--              :on-change="EditleFileChange"-->
<!--            >-->
<!--              <el-button size="medium" plain icon="el-icon-top" style="color: #4d90f1;border: 1px solid #4d90f1">上传文件</el-button>-->
<!--            </el-upload>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
<!--        <div class="btn">-->
<!--          <button  @click="dialogEdit = false">取消</button>-->
<!--          <button  @click="submitEdit">确定</button>-->
<!--        </div>-->
<!--      </el-dialog>-->
<!--    </div>-->
    <div class="see-dialog">
      <el-dialog
        :visible.sync="dialogSee"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">查看文件列表</span>
        </div>
        <el-table
          :data="tableDataSee"
          style="width: 100%"
          max-height="500"
          :header-cell-style="{
              color: '#333333',
              backgroundColor: '#FBFBFD',
            }"
          row-key="id"
          border
          lazy>
          <el-table-column
            label="序号"
            type="index"
            align="center"
            width="62">
          </el-table-column>
          <el-table-column
            label="文件名"
            prop="FileName"
            align="center"
            min-width="100">
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="200">
            <template slot-scope="scope">
              <el-link
                style="font-size:18px;color: #3978E7;margin-right: 11px"
                type="text"
                :underline="false"
                @click="delflie(scope.row)"
              ><i class="el-icon-delete"></i>删除</el-link>
              <el-link
                style="font-size:18px;color: #3978E7"
                type="text"
                :underline="false"
                @click="pre(scope.row.Id)"
              ><img src="../../../assets/image/notice/query.png" alt="" style="width: 16px;height: 16px;vertical-align: -3px">查看</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
    <div class="FinanceVoucher-dialog">
      <el-dialog
        top="35vh"
        :visible.sync="delFinanceDialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该文件，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delFinanceDialog = false">取消</button>
          <button @click="sumDelFinance">确定</button>
        </div>
      </el-dialog>
    </div>
    <div class="FinanceVoucher-dialog">
      <el-dialog
        top="35vh"
        :visible.sync="delFileDialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该文件，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delFileDialog = false">取消</button>
          <button @click="sumDelFile">确定</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import reseacrh from "../../../api/research.js"
import {mapState} from "vuex";
import treedata from "@/components/treedata";
import research from "../../../api/research.js";
import {downloadFileId,downLoadXls} from "@/utils/downloadUrl";
import {exportTableType} from "@/api/fileManagement";
export default {
  data(){
    return{
      exportData: "FileName,Remarks,HospitalName,TopicName,UserName,CreateDate",
      exportDataName: "文件名称,说明,所属单位,所属项目,上传人,上传时间",
      DataGuidList: [], // 打包文件的DataGuid数组
      DataGuidItem: "", // 查看打开的guid
      delFileItem: {}, // 删除查看的对象
      delFileDialog: false, // 删除查看的文件
      delFinanceItem: {}, // 删除文件的对象
      delFinanceDialog: false, // 删除文件
      dialogList: false,
      Key: '',// 查询关键字
      editRemarks: "", // 编辑文件说明
      fileEditList: [],//编辑文件上传的列表
      tableDataSee: [],//查看数据
      dialogSee: false,
      items: [1,2,3,4,5],
      options: [],
      editForm: {
        Id: null,
        FileName: "",
        CreateDate: '',
        UserName: '',
        TopicId: "",
        SerialNumber: ""
      },
      dialogEdit: false, // 编辑弹框
      fileName: "", // 文件名称
      Remarks:"", // 备注说明
      fileLists:[], // 上传文件列表
      dialogVisible:false, //上传文件弹框
      multipleSelection: [],//多选框数据
      tableData: [],
      ppt:"ppt",
      imglist:[],
      num:-1,
      value:"",
      currentPage:1,
      PageCount:0,
      eachpage:10,
      disabled:false
    }
  },
  computed: mapState(["projectId"]),
  components:{
    treedata,
  },
  created(){
    this.filelist()
  },
  methods:{

    handleSizeChange(val){  //切换每页条数
      this.eachpage = val
      this.filelist()
    },
    getRowKey(row){       //回显
      console.log(row);
      return row.Id;
    },

    projectIdClick(data) { //树型结构传递过来的课题id
      this.$store.commit('SaveId',data)
      this.currentPage = 1;
      this.eachpage = 10;
      this.PageCount = 0;
      this.Key = ""
      this.filelist()
    },
    search() { // 查询
      this.filelist()
    },
    handleRemove(file, fileList) {
      this.fileLists = fileList;
      console.log(this.fileLists)
    },

    handleFileChange(file, fileList) {
      this.fileLists = fileList;
      // console.log(this.fileLists)
      // console.log(file);
    },
    // 上传时钩子
    fileUploading(event, file, fileList){
      console.log(event);
      console.log(file);
      console.log(fileList);
    },
    EditleFileChange(file, fileList) {
      this.fileEditList = fileList;
    },
    // submitEdit() { // 编辑文件更新
    //   let objUpload = {
    //     Id: this.editForm.Id,
    //     TopicId : this.editForm.TopicId,
    //     Remarks: this.editRemarks,
    //   };
    //   let fd = new FormData();
    //   this.fileEditList.forEach(file => {
    //     fd.append('file',file.raw)
    //   })
    //   fd.append("data", JSON.stringify(objUpload));
    //   console.log(fd)
    //   reseacrh.updateFinancFile(fd).then((res) => {
    //     if (res.data.Status == 1) {
    //       this.dialogEdit = false;
    //       this.filelist()
    //       this.fileEditList = []
    //       this.$message.success(res.data.Message);
    //     }else{
    //       this.$message.error(res.data.Message)
    //     }
    //   });
    // },
    
    // 选择的文件上传
    beforeUpload(file){ 
      let objUpload = {
        Id: this.projectId,
        Type:4,
        Remarks: this.Remarks,
      };
      console.log(file);
      let fd = new FormData();
      fd.append("file", file);
      fd.append("data", JSON.stringify(objUpload));
      reseacrh.TopicUploadFile(fd).then((res) => {
        if (res.data.Status == 1) {
          this.dialogVisible = false;
          this.Remarks = "";
          this.fileName = ""
          this.filelist()
          this.disabled = false
          this.$message.success(res.data.Message);
        }else{
          this.disabled = false
          this.$message.error(res.data.Message)
        }
        console.log(res);
        
      });
      return true;
    },
       determine(){ //确定文件上传
      if (this.Remarks == "") {
        this.$message.error('说明不能为空')
        return false
      }
      if (this.fileLists.length == 0) {
        this.$message.error('上传文件不能为空')
        return false
      }
      this.$refs.newupload.submit();
      this.disabled = true
      
      // let objUpload = {
      //   TopicId : this.projectId,
      //   Type: 4,
      //   Remarks: this.Remarks,
      // };
      // let fd = new FormData();
      // this.fileLists.forEach(file => {
      //   fd.append('file',file.raw)
      // })
      // fd.append("data", JSON.stringify(objUpload));
      // console.log(fd)
      // reseacrh.addFinancFile(fd).then((res) => {
      //   if (res.data.Status == 1) {
      //     this.dialogVisible = false;
      //     this.Remarks = "";
      //     this.fileLists = []
      //     this.filelist()
      //     this.$message.success(res.data.Message);
      //   }else{
      //     this.$message.error(res.data.Message)
      //   }
      // });
    },
    add() { // 上传文件
      this.dialogVisible=true
      this.Remarks = "";
      this.fileLists = []
    },
    delIcon(index) {
      console.log(this.items,'删除前')
      this.items.splice(index,1)
      console.log(this.items,'删除后')
    },
    sumDelFinance() {
      reseacrh.alldelet({Id:this.delFinanceItem.Id}).then(res=>{
      // reseacrh.delFinancFile({id:this.delFinanceItem.Id}).then(res=>{  // 开始的删除凭证文件
        console.log(res)
        if(res.data.Status==1){
          this.$message.success(res.data.Message)
          this.delFinanceDialog = false
          this.filelist()
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    delFinanceZ(item) {
      this.delFinanceItem = item
      this.delFinanceDialog = true
    },
    see(item) {
      this.DataGuidItem = item.DataGuid
      reseacrh.preFinancFile(item.DataGuid).then(res=>{
        if (res.data.Status==1) {
        this.tableDataSee = res.data.ExtraData
          if (this.tableDataSee.length == 0) {
            this.$message.warning('您还没有上传文件')
          } else {
            this.dialogSee = true
          }
        } else {
          this.$message.error(res.data.Message)
        }
      })
    },
    sumDelFile() {
      reseacrh.alldelet({Id:this.delFileItem.Id}).then(res=>{
        if(res.data.Status==1){
          this.$message.success(res.data.Message)
          this.delFileDialog = false
          this.filelist()
          reseacrh.preFinancFile(this.DataGuidItem).then(res=>{
            if (res.data.Status==1) {
              this.tableDataSee = res.data.ExtraData
            } else {
              this.$message.error(res.data.Message)
            }
          })
        }else{
          this.$message.success(res.data.Message)
        }
      })
    },
    delflie(item){//删除查看的文件
      this.delFileItem = item
      this.delFileDialog = true
    },
    edit(item) { //编辑
      this.dialogEdit = true
      // reseacrh.preFinancFile(item.DataGuid).then(res => {
      //   console.log(res,'编辑')
      // })
      this.editForm.Id = item.Id
      this.editRemarks = item.Remarks
      this.editForm.CreateDate = item.CreateDate.substring(0,10)
      this.editForm.FileName = item.FileName
      this.editForm.UserName = item.UserName
      this.editForm.SerialNumber = item.SerialNumber
      this.editForm.TopicId = item.TopicId
    },
    handleSelectionChange(val) { //多选
      this.multipleSelection = val;
    },
    handleCurrentChange(val){
      this.currentPage=val
      this.filelist()
      console.log(`当前页: ${val}`);
    },
    pre(id){
      reseacrh.preview(id).then(res=>{
        // if(res.data.Status==1){
        //   if(/\.(pdf|PDF|png|PNG|jpg|JPG)$/.test(res.data.ExtraData.Url)){
        //     window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url);
        //   }else{
        //     this.downloadfile(id)
        //     this.$message.warning("暂时只支持pdf文件预览")
        //   }
        // }

        this.downloadfile(id,true)
      })
    },
    filelist(){
      var fileobj={
        TopicId:this.projectId,
        Type: 4,
        Remarks: this.Key,
        StartDate: "",
        EndDate: "",
        PageIndex:this.currentPage-1,
        PageSize:this.eachpage,
      }
      reseacrh.GetPagedTopicDataType(fileobj).then(res=>{
        if(res.data.Status==1){
          this.tableData = res.data.Entity
          this.PageCount=res.data.TotalRowsCount
        }else{
          this.$message.warning(res.data.Message)
        }
      })
    },
    dowFiles() {
      this.DataGuidList = []
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择要下载的文件')
        return  false
      }
      console.log(this.multipleSelection)
      this.multipleSelection.forEach(item => {
        this.DataGuidList.push(item.DataGuid)
      })
      reseacrh.FinancZFileZip({DataGuids: this.DataGuidList}).then(res => {
        console.log(res,'拼接图片')
        if (res.status == 200) {
          let data = res.data;
          // let filename = decodeURIComponent(res.headers.filename)
          // downLoadXls(data,filename)
          downLoadXls(data,"财务档案相关文件.zip")
        } else {
          this.$message.error(res.data.Message)
        }
      })
    },
    downloadfile(id,preView){
      downloadFileId(id,preView)
    },
    exportFiles() {
      var exportobj={
        TopicId:this.projectId,
        Type: 4,
        Remarks: this.Key,
        StartDate: "",
        EndDate: "",
        PageIndex:this.currentPage-1,
        PageSize:this.eachpage,
        titleName: this.exportDataName,
        titleField: this.exportData,
        ExportFileName: "财务档案相关文件"
      }
      exportTableType(exportobj).then(res=> {
        if (res.status == 200) {
          let data = res.data;
          let filename = decodeURIComponent(res.headers.filename)
          downLoadXls(data,filename)
        } else {
          this.$message.error("导出失败")
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../styles/mixin.scss";
.FinanceZ-page {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  .content_left {
    width: 260px;
    height: 94%;
    margin-top: 2%;
    border-radius: 10px;
    //box-sizing: border-box;
    background: white;
  }
  .FinanceZ-content{
    //width: 1340px;
    width: 86%;
    height: 94%;
    margin-left: 24px;
    margin-top: 2%;
    margin-right: 24px;
    background: #FFFFFF;
    padding: 1% 1% 0 1%;
    border-radius: 10px;
    box-sizing: border-box;
    .FinanceZ-header-upload{
      width: 100%;
      margin-bottom: 20px;
      span {
        display: inline-block;
        color: #69696A;
        @include add-size($font_size_16);
      }
      /deep/.el-button {
        @include add-size($font_size_16);
      }
      /deep/.el-input {
        width: 370px;
        @include add-size($font_size_16);
        margin-right: 20px;
        margin-left: 10px;
      }
    }
  }
}
.FinanceZ-dialog {
  /deep/.el-dialog {
    width: 510px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      margin-bottom: 20px;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .el-form {
      .el-form-item {
        .del-img {
          width: 120px;
          height: 120px;
          position: relative;
          .del-icon {
            position: absolute;
            font-size: 20px;
            top: 0;
            right: 0;
          }
          .img {
            width: 120px;
            height: 120px;
          }
        }
        .el-form-item__label {
          @include add-size($font_size_16);
          color: #666666;
        }
        .el-input__inner {
          //background: #F4F7F9;
        }
        .el-input {
          @include add-size($font_size_16);
          margin-right: 10px;
        }
        .el-textarea__inner {
          @include add-size($font_size_16);
          opacity: 1;
          //background: #F4F7F9;
        }
      }
    }
    .btn {
      margin-top: 52px;
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
      }
    }
  }
}
.see-dialog {
  /deep/.el-dialog {
    width: 510px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      margin-bottom: 20px;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
  }
}
.FinanceVoucher-dialog /deep/.el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    border-radius: 10px 10px 0px 0px;
    background: #3388ff;
    margin-bottom: 20px;
    .header-title {
      .title-name {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 4px solid #ffffff;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 4px;
        margin-bottom: 6px;
      }
      .title-age {
        vertical-align: top;
        display: inline-block;
        @include add-size($font_size_16);
        color: #ffffff;
      }
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
  .btn {
    margin-top: 52px;
    text-align: center;
    button:nth-child(1) {
      width: 180px;
      background: #ffffff;
      border: 1px solid #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #3388ff;
      margin-right: 20px;
    }
    button:nth-child(2) {
      width: 180px;
      //background: #ffffff;
      border: 1px solid #3388ff;
      background: #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #fff;
    }
  }
}
.block {
  margin-top: 20px;
}
.content /deep/ .el-link {
  font-weight: 400;
}
.content /deep/.el-table th {
  @include add-size($font_size_16);
  vertical-align: middle;
}
.content /deep/.el-table__row {
  @include add-size($font_size_16);
  vertical-align: middle;
}
.content {
  ::-webkit-scrollbar {
    width: 8px!important;
    height: 4px;

  }
  ::-webkit-scrollbar-thumb {
    //width: 20px;
    //height: 4px;
    background-color: #1890ff;
    border-radius: 10px;
  }
}
</style>
